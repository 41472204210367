import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;
const editor = document.createElement("textarea");
document.getElementById("app")?.appendChild(editor);

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// // You can even start sending messages before the connection is open!
// conn.addEventListener("message", (event) => {
//   add(`Received -> ${event.data}`);
// });

// // Let's listen for when the connection opens
// // And send a ping every 2 seconds right after
// conn.addEventListener("open", () => {
//   add("Connected!");
//   add("Sending a ping every 2 seconds...");
//   // TODO: make this more interesting / nice
//   setInterval(() => {
//     conn.send("ping");
//   }, 1000);
// });

// When the textarea changes, send the current text to the server
editor.addEventListener("input", () => {
  conn.send(editor.value);
});

// When a message is received, update the textarea with the new text
conn.addEventListener("message", (event) => {
  editor.value = event.data;
});

// Let's listen for when the connection opens
conn.addEventListener("open", () => {
  console.log("Connected!");
});
